import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Image, Slideshow, Typography, Card } from '../../components/library'

function section() {
    return (
        <LibraryWrapper>
            <Slideshow to="/components">
                <Card>
                    <Image src="/onboarding-slide-0.jpg" />
                    <Typography variant="body1" align="center">
                        A watch-like wearable device that allows your child to report their asthma symptoms real-time.
                    </Typography>
                </Card>
                <Card>
                    <Image src="/onboarding-slide-1.jpg" />
                    <Typography variant="body1" align="center">
                        Monitor your child’s lung function using a hand-held spirometer
                    </Typography>
                </Card>
                <Card>
                    <Image src="/onboarding-slide-2.jpg" />
                    <Typography variant="body1" align="center">
                        Work together with your child to track their asthma symptoms and asthma control through a companion mobile app
                    </Typography>
                </Card>
                <Card>
                    <Image src="/onboarding-slide-3.jpg" />
                    <Typography variant="body1" align="center">
                        Set goals with your child, earn rewards, and improve asthma care together!
                    </Typography>
                </Card>
            </Slideshow>
        </LibraryWrapper>
    )
}
export default section
